import { FORM_UUID_TRAVEL_CLINIC } from 'constants/app.constants';
import { IProfile, TGuest } from 'interfaces/user.interfaces';
import { EGender } from 'interfaces/patients.interfaces';
import {
  EAppointmentFormStatus,
  EQuestionType,
  IForm,
  TQuestion,
} from '../interfaces/form.interfaces';

export const getFormStatus = (
  form: IForm,
  patient?: IProfile | null,
  guest?: TGuest | null,
): EAppointmentFormStatus => {
  switch (form?.uuid) {
    case FORM_UUID_TRAVEL_CLINIC: {
      if (form.sections?.length) {
        const destinationQuestions = form.sections[0].questions
          .reduce<TQuestion[][]>(
            (result, item) => {
              if (item.type === EQuestionType.SEPARATOR) {
                if (result[result.length - 1].length > 0) {
                  result.push([]);
                }
              } else {
                result[result.length - 1].push(item);
              }
              return result;
            },
            [[]],
          )
          .filter(group => group.length > 0);

        return form.sections.some(
          (section, index) =>
            section.questions?.length &&
            !(
              index === 1 &&
              (patient?.customer?.gender === EGender.MALE ||
                patient?.customer?.gender === EGender.NON_BINARY ||
                guest?.gender === EGender.MALE ||
                guest?.gender === EGender.NON_BINARY)
            ) &&
            section.questions.some(
              question => question.type === EQuestionType.BINARY && question.value !== false,
            ),
        ) ||
          destinationQuestions.every(questions => !questions[0].value) ||
          destinationQuestions.some(
            questions =>
              questions[0].value &&
              questions.some(question => question.required && !question.value && !question.values),
          )
          ? EAppointmentFormStatus.NEEDS_REVIEW
          : EAppointmentFormStatus.COMPLETED;
      }
      return EAppointmentFormStatus.COMPLETED;
    }
    default: {
      return EAppointmentFormStatus.COMPLETED;
    }
  }
};
