import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import classNames from 'classnames';

import BeWellLogo from 'assets/svg/bewell.svg';
import NHSLogo from 'assets/svg/nhs.png';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { signOutPatient } from 'modules/auth/actions/auth.actions';
import { useShowFormNHSLogo } from 'modules/forms/hooks/useShowFormNHSLogo';
import { getBrand } from 'helpers/branding.helper';
import SignOut from './SignOut';

interface IProps {
  title?: string;
  description?: string;
  step?: number;
  maxSteps?: number;
  isComplete?: boolean;
  showSignOut?: boolean;
  isScrolledFromTop?: boolean;
}

const PageHeader = ({
  title,
  description,
  step,
  maxSteps = 4,
  isComplete,
  showSignOut,
  isScrolledFromTop,
}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const branding = useTypedSelector(state => state.app.branding);
  const location = useTypedSelector(state => state.app.location);
  const formTitle = useTypedSelector(state => state.form.formTitle);
  const { showFormNHSLogo } = useShowFormNHSLogo();

  // const { isScrolledFromTop } = useScrollStatus('page-main-scrollable');

  const onLogoClick = () => {
    if (location) {
      dispatch(signOutPatient());
    }
  };

  return (
    <header className={classNames('page-header', { 'page-header--shadow': isScrolledFromTop })}>
      <div className="page-header-top">
        <div className="page-header__logo">
          {/* eslint-disable-next-line */}
          <img
            src={(branding?.logos?.portal as string) || BeWellLogo}
            alt={getBrand() || intl.get('common.brandTitle')}
            onClick={onLogoClick}
          />
        </div>
        {showFormNHSLogo && (
          <div className="page-header__service-logo">
            <img src={NHSLogo} alt={intl.get('forms.nhs')} />
          </div>
        )}
      </div>

      <div className="page-header-row">
        <div className="page-header-row-title">
          <h1 className="page-header-row__title">
            {title || `${intl.get('forms.patientIntakeForm')}${formTitle ? `: ${formTitle}` : ''}`}
          </h1>
          {!!description && <div className="page-header-row__description">{description}</div>}
        </div>

        {(step || isComplete) && (
          <div className="stepper">
            <div
              className={classNames('stepper__label', { 'stepper__label--complete': isComplete })}
            >
              {isComplete
                ? `${intl.get('forms.complete')}`
                : `${intl.get('forms.step')} ${step || 1}/${maxSteps}`}
            </div>
            <div className="stepper__bar">
              <div
                className="stepper__progress"
                style={{ width: isComplete ? '100%' : `${(100 / maxSteps) * (step || 1)}%` }}
              />
            </div>
          </div>
        )}

        {!!showSignOut && <SignOut />}
      </div>
    </header>
  );
};

export default memo(PageHeader);
