import { useMemo } from 'react';

import { useTypedSelector } from 'hooks/useTypedSelector';
import { FORM_UUID_TRAVEL_CLINIC } from 'constants/app.constants';

export const useShowFormNHSLogo = (): { showFormNHSLogo: boolean } => {
  const formUuid = useTypedSelector(state => state.form.formUuid);

  const showFormNHSLogo = useMemo(() => formUuid !== FORM_UUID_TRAVEL_CLINIC, [formUuid]);

  return { showFormNHSLogo };
};
